import { combineReducers } from 'redux';
import authReducer from './authReducer';
import controllerReducer from './controllerReducer';
import filterReducer from './filterReducer';
import eventsReducer from './eventsReducer';
import objectsReducer from "./objectsReducer";
import progressTrackerReducer from "./progressTrackerReducer";
import toastReducer from "./toastReducer";
import commentsReducer from "./commentsReducer";

const allReducers = combineReducers({
  auth: authReducer,
  controller: controllerReducer,
  filter: filterReducer,
  events: eventsReducer,
  objects: objectsReducer,
  progressTracker: progressTrackerReducer,
  toast: toastReducer,
  comments: commentsReducer,
});

export default allReducers;
