import axios from 'axios';
import { put } from 'redux-saga/effects';
import { SET_OBJECTS_ACTION, SHOW_OBJECT_ACTION_SUCCESS } from '../actions/types';
import { checkSession, getAccessToken } from './authSagas';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export function* getObjectsSaga(params) {
  try {
    const token = yield getAccessToken()
    const objResponseTwo = yield axios.get(`${BASE_URL}/ObjectList?start=${params.params.startingDate}&end=${params.params.endingDate}&search=${params.params.search}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (objResponseTwo.data.length > 0) {
      yield put({ type: SET_OBJECTS_ACTION, data: objResponseTwo.data });
    }

    if (objResponseTwo.data.length < 1) {
      yield put({ type: SET_OBJECTS_ACTION, data: [] });
    }

  } catch (e) {
    if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
      checkSession();
    }
  }
}

export function* showObjectSaga(params) {
  try {
    const token = yield getAccessToken()
    let showObjectResponse = yield axios.get(`${BASE_URL}/Object?start=${params.params.startingDate}&end=${params.params.endingDate}&object=${params.params.name}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (showObjectResponse.status === 200) {
      yield put({ type: SHOW_OBJECT_ACTION_SUCCESS, data: showObjectResponse.data[0].items });
    }
  }
  catch (e) {
    if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
      checkSession();
    }
  }
}
