import { connect, Provider } from 'react-redux';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { Redirect, Route, Switch, useLocation, useHistory } from 'react-router-dom';
import loadable from '@loadable/component';
import { Grid } from '@material-ui/core';
import { css } from 'aphrodite';
import { store } from './store';
import useWindowDimensions from './utils/windowDimensions';
import './App.css';
import { globalStyles } from './globalStyles';
import {
    appBackground,
    backgroundWhite,
    grey50,
    primary400,
    primaryAccent,
    primaryActionColor,
    titleBlack
} from "./colors";
import moment from "moment";
import SignOut from './views/Authentication/SignOut';
import { checkSession } from './sagas/authSagas';
import { Hub } from 'aws-amplify';
import { getEmailFromJWT } from './utils/helper';


const AppDrawer = loadable(() => import('./components/AppDrawer/AppDrawer'));
const NavigationBar = loadable(() => import('./components/NavigationBar/NavigationBar'));
const Paginator = loadable(() => import('./components/Pagination/Pagination'));
const Toast = loadable(() => import('./components/Toast/Toast'));
const Comments = loadable(() => import('./components/Comments/Comments'));
const Authentication = loadable(() => import('./views/Authentication/Authentication'));
const Cockpit = loadable(() => import('./views/Cockpit/Cockpit'));
const ProgressTracker = loadable(() => import('./views/ProgressTracker/ProgressTracker'));
const Events = loadable(() => import('./views/Events/Events'));
const Objects = loadable(() => import('./views/Objects/Objects'));

const THEME = createMuiTheme({
    typography: {
        fontFamily: 'Poppins',
    },
    palette: {
        primary: {
            main: `${primaryAccent}`,
            light: `${primary400}`,
            dark: `${primary400}`,
        },
    },
});

THEME.typography.h1 = {
    fontSize: 22,
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: titleBlack,
    [THEME.breakpoints.up('md')]: {
        fontSize: 24,
        fontWeight: 600,
        color: titleBlack,
    },
};
THEME.typography.h2 = {
    fontSize: 18,
    fontWeight: 600,
    fontFamily: 'Poppins',
    color: titleBlack,
    [THEME.breakpoints.up('md')]: {
        fontSize: 20,
        fontWeight: 600,
        color: titleBlack,
    },
};
THEME.typography.h3 = {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: grey50,
    [THEME.breakpoints.up('md')]: {
        fontSize: 16,
        fontWeight: 400,
        color: grey50,
    },
};
THEME.typography.h4 = {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: grey50,
    [THEME.breakpoints.up('md')]: {
        fontSize: 14,
        fontWeight: 400,
        color: grey50,
    },
};
THEME.typography.h5 = {
    fontSize: 10,
    fontWeight: 400,
    fontFamily: 'Poppins',
    color: grey50,
    [THEME.breakpoints.up('md')]: {
        fontSize: 12,
        fontWeight: 400,
        color: grey50,
    },
};
THEME.typography.h6 = {
    fontSize: 8,
    fontWeight: 500,
    fontFamily: 'Poppins',
    color: grey50,
    [THEME.breakpoints.up('md')]: {
        fontSize: 10,
        fontWeight: 500,
        color: grey50,
    },
};
THEME.typography.subtitle1 = {
    fontSize: 12,
    fontWeight: 400,
    color: primaryActionColor,
    fontFamily: 'Poppins',
    [THEME.breakpoints.up('md')]: {
        fontSize: 14,
    },
};
THEME.typography.subtitle2 = {
    fontSize: 12,
    [THEME.breakpoints.up('md')]: {
        fontSize: 12,
    },
};
THEME.typography.body1 = {
    fontSize: 10,
    fontWeight: 400,
    color: backgroundWhite,
    fontFamily: 'Poppins',
    [THEME.breakpoints.up('md')]: {
        fontSize: 12,
    },
};
THEME.typography.body2 = {
    fontSize: 12,
    fontWeight: 700,
    [THEME.breakpoints.up('md')]: {
        fontSize: 14,
    },
};

const App = ({
    showEvent,
    showObject,
    progressTrackerLoading,
    progressData,
}) => {
    const { width } = useWindowDimensions();
    const { pathname } = useLocation()
    const history = useHistory()

    useEffect(() => {
        if (!['/login', '/sign-out'].includes(pathname)) {
            checkSession()
        }
    }, [pathname])

    useEffect(() => {
        if (localStorage.getItem('start') === '') {
            localStorage.setItem('start', moment(Date.now()).subtract(localStorage.getItem('defaultWindow'), 'days').format('YYYY-MM-DD'));
            localStorage.setItem('end', moment(Date.now()).format('YYYY-MM-DD'));
        }

        if (localStorage.getItem('email') && !localStorage.getItem('userPrefs')) {
            let config = {
                peopleMetric: true,
                alertsMetric: true,
                deliveriesMetric: true,
                activeEventsMetric: true,
                weatherWidget: true,
                imageDisplay: true,
                peopleGraph: true,
                alertsGraph: true,
                trafficGraph: true,
                vehicleFlagging: true,
                vehicleFlaggingCount: true,
                peopleMetricX: 0,
                peopleMetricY: 0,
                alertsMetricX: 0,
                alertsMetricY: 0,
                eventsMetricX: 0,
                eventsMetricY: 0,
                deliveriesMetricX: 0,
                deliveriesMetricY: 0,
                weatherWidgetX: 0,
                weatherWidgetY: 0,
                imageDisplayX: 0,
                imageDisplayY: 0,
                vehicleFlaggingX: 0,
                vehicleFlaggingY: 0,
                peopleGraphX: 0,
                peopleGraphY: 0,
                alertsGraphX: 0,
                alertsGraphY: 0,
                flaggingGraphX: 0,
                flaggingGraphY: 0,
                stackedGraphX: 0,
                stackedGraphY: 0,
            };

            localStorage.setItem('userPrefs', JSON.stringify(config));
        }

        if (localStorage.getItem('userPrefs')) {
            if (!JSON.parse(localStorage.getItem('userPrefs')).weatherWidget) {
                let prefs = JSON.parse(localStorage.getItem('userPrefs'));
                prefs.weatherWidget = true;
                localStorage.setItem('userPrefs', JSON.stringify(prefs));
            }
        }
    }, []);

    const isPublicRoute = () => {
        return (pathname.includes('signup') || pathname.includes('login') || pathname.includes('forgot'));
    };

    const isMobile = () => {
        return width && width <= 475;
    };

    useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch (event) {
                case "signIn":
                case "cognitoHostedUI":
                    console.log("Authenticated...");
                    const email = getEmailFromJWT(data.signInUserSession.idToken.jwtToken);
                    localStorage.setItem('email', email);
                    if (!localStorage.getItem('defaultWindow')) {
                        localStorage.setItem('defaultWindow', 7);
                        localStorage.setItem('start', moment(Date.now()).subtract(localStorage.getItem('defaultWindow'), 'days').format('YYYY-MM-DD'));
                        localStorage.setItem('end', moment(Date.now()).format('YYYY-MM-DD'));
                    }
                    history.push("/progress-tracker")
                    break;
                case "signIn_failure":
                case "cognitoHostedUI_failure":
                    console.log("Error", data);
                    break;
                default:
                    break
            }
        });
    }, []);

    return (
        <Provider store={store}>
            <MuiThemeProvider theme={THEME}>
                <Toast />
                <Grid className={css(isPublicRoute() && globalStyles.authenticationBg)} container>
                    {localStorage.getItem('email') && !isMobile() && !isPublicRoute() && (
                        <Grid item md={2} lg={2} xl={2}>
                            <AppDrawer pathname={pathname} />
                        </Grid>
                    )}

                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={localStorage.getItem('email') ? 10 : 12}
                        lg={localStorage.getItem('email') ? 10 : 12}
                        xl={localStorage.getItem('email') ? 10 : 12}
                    >
                        <section
                            style={{
                                backgroundColor: appBackground,
                                margin: width > 476 && !progressTrackerLoading && progressData && pathname.includes("progress-tracker") ? '0' : '0 0 3em 0',
                            }}
                        >
                            {localStorage.getItem('email') && !isPublicRoute() && <NavigationBar />}
                        </section>

                        <Switch>
                            <Route exact path="/" render={() => <Redirect to="/progress-tracker" />} />
                            <Route path="/login" component={Authentication} />
                            <Route path="/signup" component={Authentication} />
                            <Route path="/forgot" component={Authentication} />
                            <Route path="/sign-out" component={SignOut} />
                            <PrivateRoute exact path="/cockpit" component={Cockpit} />
                            <PrivateRoute exact path="/insights/events" component={Events} />
                            <PrivateRoute exact path="/insights/objects" component={Objects} />
                            <PrivateRouteMBN exact path="/progress-tracker" component={ProgressTracker} />
                            <PrivateRouteMBN exact path="/progress-tracker-2" component={ProgressTracker} />
                            <PrivateRouteMBN exact path="/hse" component={Events} />
                            <PrivateRouteMBN exact path="/workforce" component={Events} />
                            <PrivateRouteMBN exact path="/equipment" component={Events} />
                            <PrivateRoute exact path="/delivery" component={Events} />
                        </Switch>

                        {localStorage.getItem('email') &&
                            (pathname.includes('events') ||
                                // pathname.includes('objects') ||
                                pathname.includes('hse') ||
                                pathname.includes('workforce') ||
                                pathname.includes('equipment') ||
                                pathname.includes('delivery')) && (showEvent || showObject) &&
                            <Comments pathname={pathname} />
                        }

                        {false && <section
                            style={{
                                backgroundColor: appBackground,
                                margin: '0 0 3em 0',
                            }}
                        >
                            {(localStorage.getItem('email') && !showEvent) && <Paginator pathname={pathname} />}
                        </section>}
                    </Grid>
                </Grid>
            </MuiThemeProvider>
        </Provider>
    );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                <Component {...props} />
            }
        />
    );
};

const PrivateRouteMBN = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props =>
                (localStorage.getItem('email') &&
                    localStorage.getItem('email') &&
                    !localStorage.getItem('email').includes('webcor')) ?
                    <Component {...props} /> : null
            }
        />
    );
};

const mapStateToProps = (state) => ({
    showEvent: state.events.showEvent,
    showObject: state.objects.showObject,
    progressTrackerLoading: state.progressTracker.progressTrackerLoading,
    progressData: state.progressTracker.progressData,
});

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(App);
