import {HIDE_TOAST, SHOW_TOAST, SHOW_WAITING_TOAST} from "../actions/types";

const initialState = {
    showToast: false,
    toastMessage: null,
    toastType: null,
};

export default function (state = initialState, action) {

    switch (action.type) {
        case SHOW_TOAST:
            return {
                ...state,
                showToast: action.data.showToast,
                toastMessage: action.data.toastMessage,
                toastType: action.data.toastType,
            }

        case SHOW_WAITING_TOAST:
            return {
                ...state,
                showToast: action.params.showToast,
                toastMessage: action.params.toastMessage,
                toastType: action.params.toastType,
            }

        case HIDE_TOAST:
            return {
                ...state,
                showToast: false,
                toastMessage: "",
                toastType: "",
            }

        default:
            return state;
    }
}
