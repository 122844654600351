import axios from "axios";
import { INDEXER_URL_V2_V2, checkSession, getAccessToken } from "./authSagas";
import { put } from "redux-saga/effects";
import {
    SET_ALL_COMMENTS,
    SET_ALL_COMMENTS_COUNT, SET_ALL_COMMENTS_PAGE,
    SHOW_TOAST,
    UPDATE_ALL_COMMENTS, UPDATE_COMMENT,
} from "../actions/types";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export function* getAllCommentsSaga(params) {
    try {
        const token = yield getAccessToken()
        let getAllCommentsResponse = yield axios.post(`${BASE_URL}/comments/api/list/?page_size=10&page=1`, { id: params.params.id, type: params.params.type }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        yield put({ type: SET_ALL_COMMENTS, data: getAllCommentsResponse.data.results });
        yield put({
            type: SET_ALL_COMMENTS_COUNT, data: {
                nextPage: getAllCommentsResponse.data.next_page,
                pageCount: getAllCommentsResponse.data.page_count,
                count: getAllCommentsResponse.data.count
            }
        });
    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }
        yield put({
            type: SHOW_TOAST,
            data: {
                showToast: true,
                toastMessage: 'Could not load comments at this time',
                toastType: 'error'
            }
        });
    }
}

export function* getAllCommentsPageSaga(params) {
    try {
        const token = yield getAccessToken()
        let getAllCommentsResponse = yield axios.post(`${INDEXER_URL_V2_V2}/comments/api/list/?page_size=10&page=${params.params.page}`, { id: params.params.id, type: params.params.type }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        yield put({ type: SET_ALL_COMMENTS_PAGE, data: getAllCommentsResponse.data.results });
        yield put({
            type: SET_ALL_COMMENTS_COUNT, data: {
                nextPage: getAllCommentsResponse.data.next_page,
                pageCount: getAllCommentsResponse.data.page_count,
                count: getAllCommentsResponse.data.count
            }
        });
    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }
        yield put({
            type: SHOW_TOAST,
            data: {
                showToast: true,
                toastMessage: 'Could not load comments at this time',
                toastType: 'error'
            }
        });
    }
}

export function* postCommentSaga(params) {
    try {
        const token = yield getAccessToken()
        let postCommentResponse = yield axios.post(`${INDEXER_URL_V2_V2}/comments/api/create/`, params.params, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (postCommentResponse.status === 200) {
            yield put({ type: UPDATE_ALL_COMMENTS, data: postCommentResponse.data });
            // yield put({type: SET_ALL_COMMENTS_COUNT, data: postCommentResponse.data.count});
            yield put({
                type: SHOW_TOAST,
                data: {
                    showToast: true,
                    toastMessage: 'Your comment was posted successfully',
                    toastType: 'success'
                }
            });
        }
    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }
        yield put({
            type: SHOW_TOAST,
            data: {
                showToast: true,
                toastMessage: 'Could not post comment at this time, please try again later',
                toastType: 'error'
            }
        });
    }
}

export function* deleteCommentSaga(params) {
    try {
        const token = yield getAccessToken()
        let deleteCommentResponse = yield axios.post(`${INDEXER_URL_V2_V2}/comments/api/archive/`, params.params, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (deleteCommentResponse.status === 200) {
            yield put({ type: UPDATE_COMMENT, data: deleteCommentResponse.data });
            yield put({ type: SET_ALL_COMMENTS_COUNT, data: deleteCommentResponse.data.count });
            yield put({
                type: SHOW_TOAST,
                data: {
                    showToast: true,
                    toastMessage: 'Your comment was deleted successfully',
                    toastType: 'success'
                }
            });
        }
    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }
        // yield put({
        //     type: SHOW_TOAST,
        //     data: {
        //         showToast: true,
        //         toastMessage: 'Could not delete comment at this time, please try again later',
        //         toastType: 'error'
        //     }
        // });
    }
}

export function* updateCommentSaga(params) {
    try {
        const token = yield getAccessToken()
        let updateCommentResponse = yield axios.post(`${INDEXER_URL_V2_V2}/comments/api/update/`, params.params, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        if (updateCommentResponse.status === 200) {
            yield put({ type: UPDATE_COMMENT, data: updateCommentResponse.data });
            // yield put({type: SET_ALL_COMMENTS_COUNT, data: updateCommentResponse.data.count});

            yield put({
                type: SHOW_TOAST,
                data: {
                    showToast: true,
                    toastMessage: 'Your comment was updated successfully',
                    toastType: 'success'
                }
            });
        }
    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }
        // yield put({
        //     type: SHOW_TOAST,
        //     data: {
        //         showToast: true,
        //         toastMessage: 'Could not update comment at this time, please try again later',
        //         toastType: 'error'
        //     }
        // });
    }
}
