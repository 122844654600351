import {CLEAR_SELECTED_CAMERAS, GET_SITE_CAMERAS, SET_SITE_CAMERAS, UPDATE_SELECTED_CAMERAS} from '../actions/types';

const initialState = {
    siteCamerasLoading: false,
    siteCameras: [],
    selectedCameras: [],
};

export default function (state = initialState, action) {

    switch (action.type) {

        case GET_SITE_CAMERAS:
            return {
                ...state,
                siteCamerasLoading: true,
            };

        case SET_SITE_CAMERAS:
            let temp = [];
            for (let i = 0; i < action.data.length; i++) {
                temp.push(action.data[i].cameraId)
            }

            return {
                ...state,
                siteCamerasLoading: false,
                siteCameras: temp,
                selectedCameras: temp,
            };

        case UPDATE_SELECTED_CAMERAS:
            return {
                ...state,
                selectedCameras: action.params,
            };

        case CLEAR_SELECTED_CAMERAS:
            return {
                ...state,
                selectedCameras: [],
            };

        default:
            return state;
    }
}
