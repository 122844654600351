import {
    DOWNLOAD_PDF_ACTION,
    DOWNLOAD_PDF_ACTION_COMPLETE,
    EVENT_FEEDBACK_ACTION, EVENT_FEEDBACK_ACTION_COMPLETED,
    GET_EVENTS_ACTION, GET_OBJECTS_ACTION,
    HANDLE_FULL_SCREEN_ACTION,
    HIDE_EVENT_ACTION, PAUSE_WEBM, PLAY_WEBM,
    SET_EVENTS_ACTION,
    SET_SELECTED_TAB,
    SHOW_EVENT_ACTION, SHOW_OBJECT_ACTION,
    UPDATE_CURRENT_EVENT_ACTION,
} from '../actions/types';

const initialState = {
    eventsLoading: true,
    eventsArr: [],
    currentEventID: 0,
    currentEventIDX: 0,
    showEvent: false,
    eventName: '',
    currentEvents: [],
    selectedTab: 'Timeline',
    fullScreen: false,
    related: [],
    color: null,
    submittingFeedback: false,
    playGIF: false,
    downloadingPDF: false,
};

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_EVENTS_ACTION:
            return {
                ...state,
                eventsLoading: true,
            };

        case SET_EVENTS_ACTION:
            return {
                ...state,
                eventsLoading: false,
                eventsArr: action.data,
            };

        case GET_OBJECTS_ACTION:
            return {
                ...state,
                eventsLoading: true,
            }

        case UPDATE_CURRENT_EVENT_ACTION:
            let temp = [...state.currentEvents];
            return {
                ...state,
                currentEventID: action.params,
                currentEventIDX: temp.length > 0 ? temp[action.params].id : 0,
            };

        case SHOW_EVENT_ACTION:
            return {
                ...state,
                showEvent: true,
                eventName: action.params.name,
                color: action.params.color,
                currentEvents: action.params.arr,
                currentEventIDX: action.params.arr[state.currentEventID].id,
                related: action.params.related,
                playGIF: state.playGIF && false,
            };

        case SHOW_OBJECT_ACTION:
            return {
                ...state,
                showEvent: true,
                color: action.params.color,
                eventName: action.params.name,
            };

        case HIDE_EVENT_ACTION:
            return {
                ...state,
                showEvent: false,
                color: null,
                eventName: '',
                currentEvents: [],
                related: [],
            };

        case SET_SELECTED_TAB:
            return {
                ...state,
                selectedTab: action.params,
            };

        case HANDLE_FULL_SCREEN_ACTION:
            return {
                ...state,
                fullScreen: !state.fullScreen,
            };

        case EVENT_FEEDBACK_ACTION:
            return {
                ...state,
                submittingFeedback: true,
            };

        case EVENT_FEEDBACK_ACTION_COMPLETED:
            return {
                ...state,
                submittingFeedback: false,
            }

        case PLAY_WEBM:
            return {
                ...state,
                playGIF: !state.playGIF,
            }

        case PAUSE_WEBM:
            return {
                ...state,
                playGIF: false,
            }

        case DOWNLOAD_PDF_ACTION:
            return {
                ...state,
                downloadingPDF: true,
            }

        case DOWNLOAD_PDF_ACTION_COMPLETE:
            return {
                ...state,
                downloadingPDF: false,
            }

        default:
            return state;
    }
}
