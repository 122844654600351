import axios from 'axios';
import { put } from 'redux-saga/effects';
import { SET_SITE_CAMERAS } from '../actions/types';
import { checkSession, getAccessToken } from './authSagas';

const BASE_URL = process.env.REACT_APP_BASE_URL;


export function* getSiteCamerasSaga() {
    try {
        const token = yield getAccessToken()
        const getSiteCamerasResponse = yield axios.get(`${BASE_URL}/camera`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        yield put({ type: SET_SITE_CAMERAS, data: getSiteCamerasResponse.data.data });
    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }
    }
}
