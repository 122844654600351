import {all, takeLatest} from 'redux-saga/effects';
import {
    REQUEST_LOG_IN,
    GET_PEOPLE_COUNT,
    GET_ALERTS_COUNT,
    GET_EVENTS_COUNT,
    GET_DELIVERIES_COUNT,
    GET_RECORDED_IMAGES,
    GET_SITE_CAMERAS,
    GET_EVENTS_ACTION,
    GET_OBJECTS_ACTION,
    GET_FLAGGING_COUNT,
    REMOVE_COMPONENT_COCKPIT,
    SHOW_OBJECT_ACTION,
    GET_PROGRESS,
    UPDATE_USER_PREFERENCES,
    EVENT_FEEDBACK_ACTION,
    GET_ALL_COMMENTS,
    POST_COMMENT_ACTION,
    DELETE_COMMENT_ACTION,
    UPDATE_COMMENT_ACTION,
    GET_ALL_COMMENTS_PAGE,
    REQUEST_SIGN_UP,
    GET_WEATHER_DATA,
    DOWNLOAD_PDF_ACTION,
} from '../actions/types';
import {
    removeComponentSaga,
    requestLogInSaga,
    requestSignUpSaga,
    updateUserPreferencesSaga
} from './authSagas';
import {
    peopleCountSaga,
    alertsCountSaga,
    eventsCountSaga,
    deliveriesCountSaga,
    recordedImagesSaga, flaggingCountSaga, getWeatherSaga,
} from './countSagas';
import {getSiteCamerasSaga} from './filterSagas';
import {downloadPDFSaga, eventFeedbackSaga, getEventsSaga} from './eventsSagas';
import {getObjectsSaga, showObjectSaga} from './objectSagas';
import {getProgressSaga} from "./progressTrackerSagas";
import {
    deleteCommentSaga,
    getAllCommentsPageSaga,
    getAllCommentsSaga,
    postCommentSaga,
    updateCommentSaga
} from "./commentsSagas";

export default function* root() {
    const sagaIndex = [
        // auth sagas
        yield takeLatest(REQUEST_LOG_IN, requestLogInSaga),
        yield takeLatest(REQUEST_SIGN_UP, requestSignUpSaga),
        // yield takeLatest(GET_USER_PREFERENCES, setPreferencesSaga),
        // yield takeLatest(SET_USER_PREFERENCES, setPreferencesSaga),
        yield takeLatest(REMOVE_COMPONENT_COCKPIT, removeComponentSaga),
        yield takeLatest(UPDATE_USER_PREFERENCES, updateUserPreferencesSaga),
        // controller sagas
        yield takeLatest(GET_PEOPLE_COUNT, peopleCountSaga),
        yield takeLatest(GET_ALERTS_COUNT, alertsCountSaga),
        yield takeLatest(GET_EVENTS_COUNT, eventsCountSaga),
        yield takeLatest(GET_FLAGGING_COUNT, flaggingCountSaga),
        yield takeLatest(GET_DELIVERIES_COUNT, deliveriesCountSaga),
        yield takeLatest(GET_RECORDED_IMAGES, recordedImagesSaga),
        yield takeLatest(GET_WEATHER_DATA, getWeatherSaga),
        // filter sagas
        yield takeLatest(GET_SITE_CAMERAS, getSiteCamerasSaga),
        // events sagas
        yield takeLatest(GET_EVENTS_ACTION, getEventsSaga),
        yield takeLatest(EVENT_FEEDBACK_ACTION, eventFeedbackSaga),
        yield takeLatest(DOWNLOAD_PDF_ACTION, downloadPDFSaga),
        // objects sagas
        yield takeLatest(GET_OBJECTS_ACTION, getObjectsSaga),
        yield takeLatest(SHOW_OBJECT_ACTION, showObjectSaga),
        // progress-tracker sagas
        yield takeLatest(GET_PROGRESS, getProgressSaga),
        // comments sagas
        yield takeLatest(GET_ALL_COMMENTS, getAllCommentsSaga),
        yield takeLatest(GET_ALL_COMMENTS_PAGE, getAllCommentsPageSaga),
        yield takeLatest(POST_COMMENT_ACTION, postCommentSaga),
        yield takeLatest(DELETE_COMMENT_ACTION, deleteCommentSaga),
        yield takeLatest(UPDATE_COMMENT_ACTION, updateCommentSaga),
    ];

    yield all(sagaIndex);
}
