import axios from 'axios';
import { put } from 'redux-saga/effects';
import moment from 'moment';
import {
    SET_ALERTS_CHART_DATA_X,
    SET_ALERTS_CHART_DATA_Y,
    SET_ALERTS_COUNT, SET_ALERTS_COUNT_DIFF, SET_DATE_DIFFERENCE,
    SET_DELIVERIES_COUNT, SET_DELIVERIES_DELTA_COUNT,
    SET_EVENTS_COUNT, SET_EVENTS_COUNT_DIFF,
    SET_FLAGGING_COUNT,
    SET_PEOPLE_CHART_DATA_X,
    SET_PEOPLE_CHART_DATA_Y,
    SET_PEOPLE_COUNT, SET_PEOPLE_COUNT_DELTA,
    SET_RECORDED_IMAGES,
    SET_RECORDED_IMAGES_COUNT,
    SET_RECORDED_IMAGES_PAGE_COUNT, SET_WEATHER_DATA,
    TRAFFIC_COUNT,
} from '../actions/types';
import { checkSession, getAccessToken } from './authSagas';

const WEATHER_API = process.env.REACT_APP_WEATHER_API

const BASE_URL = process.env.REACT_APP_BASE_URL;


export function* peopleCountSaga(params) {
    try {
        const token = yield getAccessToken()
        const peopleCountResponse = yield axios.get(
            `${BASE_URL}/PeopleCount?start=${params.params.startingDate}&end=${params.params.endingDate}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

        if (peopleCountResponse.status === 200) {
            let count = 0;
            let countDiff = 0;
            let dateArr = [];
            let countArr = [];
            peopleCountResponse.data.Person.forEach(item => {
                dateArr.push(moment(item.date).format('DD MMM YY'));
                countArr.push(item.count);
                count += item.count;
            });

            let difference = moment(localStorage.getItem('end')).diff(localStorage.getItem('start'), 'days');
            const peopleCountDiffResponse = yield axios.get(
                `${BASE_URL}/PeopleCount?start=${moment(params.params.startingDate).subtract(difference + 1, 'days').format('YYYY-MM-DD')}&end=${moment(params.params.startingDate).subtract(1, 'days').format('YYYY-MM-DD')}`);

            peopleCountDiffResponse.data.Person.forEach(item => {
                countDiff += item.count;
            });

            if (peopleCountResponse.data.Person.length < 7) {
                let countItems = peopleCountResponse.data.Person.length;
                while (countItems <= 7) {
                    dateArr.push("");
                    countArr.push(0);
                    countItems++;
                }
            }
            console.log(count, countDiff);

            yield put({ type: SET_PEOPLE_CHART_DATA_X, data: countArr });
            yield put({ type: SET_PEOPLE_CHART_DATA_Y, data: dateArr });
            yield put({ type: SET_PEOPLE_COUNT, data: count });
            yield put({
                type: SET_PEOPLE_COUNT_DELTA,
                data: countDiff
            });
        }
    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }
        else {
            yield put({ type: SET_PEOPLE_CHART_DATA_X, data: null });
            yield put({ type: SET_PEOPLE_CHART_DATA_Y, data: null });
            yield put({ type: SET_PEOPLE_COUNT, data: null });
            yield put({
                type: SET_PEOPLE_COUNT_DELTA,
                data: null
            });
        }
    }
}

export function* alertsCountSaga(params) {
    try {
        const token = yield getAccessToken()
        const alertsCountResponse = yield axios.get(
            `${BASE_URL}/EventAlertCount?start=${params.params.startingDate}&end=${params.params.endingDate},`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (alertsCountResponse.status === 200) {
            let count = 0;
            let countEvents = 0;
            const dateArr = [];
            const countArr = [];

            if (alertsCountResponse.data.alerts.length < 1) count = 0;
            else {
                let difference = moment(localStorage.getItem('end')).diff(localStorage.getItem('start'), 'days');

                alertsCountResponse.data.alerts.forEach(item => {
                    dateArr.push(moment(item.date).format('DD MMM YY'));
                    countArr.push(item.count);
                    count += item.count;
                });

                const alertsCountDiffResponse = yield axios.get(
                    `${BASE_URL}/EventAlertCount?start=${moment(params.params.startingDate).subtract(difference + 1, 'days').format('YYYY-MM-DD')}&end=${moment(params.params.startingDate).subtract(1, 'days').format('YYYY-MM-DD')}`);

                if (alertsCountDiffResponse.data.alerts.length > 0) {
                    let alertCountDiff = 0;
                    alertsCountDiffResponse.data.alerts.forEach(item => {
                        // dateArr.push(moment(item.date).format('DD MMM YY'));
                        // countArr.push(item.count);
                        alertCountDiff += item.count;
                    });
                    yield put({ type: SET_ALERTS_COUNT_DIFF, data: alertCountDiff });
                }
                if (alertsCountDiffResponse.data.events.length > 0) {
                    let eventCountDiff = 0;
                    alertsCountDiffResponse.data.events.forEach(item => {
                        // dateArr.push(moment(item.date).format('DD MMM YY'));
                        // countArr.push(item.count);
                        eventCountDiff += item.count;
                    });
                    yield put({ type: SET_EVENTS_COUNT_DIFF, data: eventCountDiff });
                }


                if (alertsCountResponse.data.alerts.length < 7) {
                    let countItems = alertsCountResponse.data.alerts.length;
                    while (countItems <= 7) {
                        dateArr.push("");
                        countArr.push(0);
                        countItems++;
                    }
                }
            }

            if (alertsCountResponse.data.events.length < 1) countEvents = 0;
            else {
                alertsCountResponse.data.events.forEach(item => {
                    // dateArr.push(moment(item.date).format('DD MMM YY'));
                    // countArr.push(item.count);
                    countEvents += item.count;
                });

                if (alertsCountResponse.data.events.length < 7) {
                    let countItems = alertsCountResponse.data.events.length;
                    while (countItems <= 7) {
                        dateArr.push("");
                        countArr.push(0);
                        countItems++;
                    }
                }

                // alertsCountResponse.data.events.map(item => {
                //     countEvents += item.count;
                // });
            }
            yield put({ type: SET_ALERTS_CHART_DATA_X, data: countArr });
            yield put({ type: SET_ALERTS_CHART_DATA_Y, data: dateArr });
            yield put({ type: SET_ALERTS_COUNT, data: count });
            yield put({ type: SET_EVENTS_COUNT, data: countEvents });
        }
    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }

        else {
            yield put({ type: SET_ALERTS_COUNT_DIFF, data: null });
            yield put({ type: SET_EVENTS_COUNT_DIFF, data: null });
            yield put({ type: SET_ALERTS_CHART_DATA_X, data: null });
            yield put({ type: SET_ALERTS_CHART_DATA_Y, data: null });
            yield put({ type: SET_ALERTS_COUNT, data: null });
            yield put({ type: SET_EVENTS_COUNT, data: null });
        }
    }
}

export function* eventsCountSaga(params) {
    try {
        const token = yield getAccessToken()
        const eventsCountResponse = yield axios.get(
            `${BASE_URL}/EntryExitCount?start=${params.params.startingDate}&end=${params.params.endingDate}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (eventsCountResponse.status === 200) {
            let count = 0;
            let flagged = 0;
            let notFlagged = 0;
            if (!eventsCountResponse.data) count = 0;
            if (eventsCountResponse.data) {
                for (const key in eventsCountResponse.data) {
                    if (key.includes('Vehicle Flagging') || key.includes('- Flagged')) flagged += eventsCountResponse.data[key];
                    if (key.includes('Vehicle Not Flagged') || key.includes('- Not Flagged')) notFlagged += eventsCountResponse.data[key];
                    count += eventsCountResponse.data[key];
                }
            }
            console.log(count, flagged, notFlagged)
            // yield put({type: VEHICLE_FLAGGING_DATA, data: [flagged, notFlagged]});
            // yield put({type: SET_EVENTS_COUNT, data: count});
        }
    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }
    }
}

export function* flaggingCountSaga(params) {
    try {
        const token = yield getAccessToken()
        const flaggingCountResponse = yield axios.get(
            `${BASE_URL}/FlaggingCount?start=${params.params.startingDate}&end=${params.params.endingDate}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (flaggingCountResponse.data.length > 0) {
            let labels = [];
            let flagged = [];
            let notFlagged = [];
            flaggingCountResponse.data.forEach((item) => {
                labels.push(moment(item.date).format('DD MMM YY'));
                flagged.push(item.flagged);
                notFlagged.push(item.notFlagged);
            });

            if (flaggingCountResponse.data.length < 7) {
                let count = flaggingCountResponse.data.length;
                while (count <= 7) {
                    labels.push("");
                    flagged.push(0);
                    notFlagged.push(0);
                    count++;
                }
            }

            yield put({ type: SET_FLAGGING_COUNT, data: { labels, flagged, notFlagged } });
        }
    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }
        else {
            yield put({ type: SET_FLAGGING_COUNT, data: null });
        }
    }
}

export function* deliveriesCountSaga(params) {
    try {
        const token = yield getAccessToken() 
        const deliveriesCountResponse = yield axios.get(
            `${BASE_URL}/DeliveryCount?start=${params.params.startingDate}&end=${params.params.endingDate}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (deliveriesCountResponse.status === 200) {
            let vehicles = [];
            let entries = [];
            let exits = [];
            let entry = 0;
            let exit = 0;
            if (!deliveriesCountResponse.data) entry = 0;
            if (deliveriesCountResponse.data) {
                for (const key in deliveriesCountResponse.data) {
                    let localEntries = 0;
                    let localExits = 0;
                    for (const key2 in deliveriesCountResponse.data[key].entry) {
                        localEntries += deliveriesCountResponse.data[key].entry[key2].count;
                        entry += deliveriesCountResponse.data[key].entry[key2].count;
                    }
                    entries.push(localEntries);

                    for (const key3 in deliveriesCountResponse.data[key].exit) {
                        localExits += deliveriesCountResponse.data[key].exit[key3].count;
                        exit += deliveriesCountResponse.data[key].exit[key3].count;
                        // for (const counts in deliveriesCountResponse.data[key].exit)
                        //exits.push(localExits);
                        // exits.push(localExits);
                    }
                    exits.push(localExits);
                    vehicles.push(deliveriesCountResponse.data[key].vehicleType);
                }
                let difference = moment(localStorage.getItem('end')).diff(localStorage.getItem('start'), 'days');
                const deliveriesCountDiffResponse = yield axios.get(
                    `${BASE_URL}/DeliveryCount?start=${moment(params.params.startingDate).subtract(difference + 1, 'days').format('YYYY-MM-DD')}&end=${moment(params.params.startingDate).subtract(1, 'days').format('YYYY-MM-DD')}`);
                let entriesDiff = 0;
                let exitsDiff = 0;
                for (const key in deliveriesCountDiffResponse.data) {
                    for (const key2 in deliveriesCountDiffResponse.data[key].entry) {
                        entriesDiff += deliveriesCountDiffResponse.data[key].entry[key2].count;
                    }

                    for (const key3 in deliveriesCountDiffResponse.data[key].exit) {
                        exitsDiff += deliveriesCountDiffResponse.data[key].exit[key3].count;
                    }
                }
                yield put({ type: SET_DATE_DIFFERENCE, data: difference });
                yield put({ type: SET_DELIVERIES_DELTA_COUNT, data: entriesDiff });
                console.log(exit, exitsDiff)
            }

            yield put({ type: TRAFFIC_COUNT, data: { vehicles, entries, exits } });
            yield put({ type: SET_DELIVERIES_COUNT, data: entry });
        }
    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }
        else {
            yield put({ type: SET_DATE_DIFFERENCE, data: null });
            yield put({ type: SET_DELIVERIES_DELTA_COUNT, data: null });
            yield put({ type: TRAFFIC_COUNT, data: null });
            yield put({ type: SET_DELIVERIES_COUNT, data: null });
        }
    }
}

export function* recordedImagesSaga(params) {
    try {
        const token = yield getAccessToken()
        const recordedImagesResponse = yield axios.get(
            `${BASE_URL}/Image?start=${params.params.startingDate}&end=${params.params.endingDate}&page=${params.params.page}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            },
        );

        if (recordedImagesResponse.status === 200) {
            yield put({ type: SET_RECORDED_IMAGES_PAGE_COUNT, data: recordedImagesResponse.data.page_count });
            yield put({ type: SET_RECORDED_IMAGES_COUNT, data: recordedImagesResponse.data.count });
            yield put({ type: SET_RECORDED_IMAGES, data: recordedImagesResponse.data.results });
            let recorderedImages = recordedImagesResponse.data.results;
            let i = 2;
            while (i <= recordedImagesResponse.data.page_count) {
                const recordedImagesResponse = yield axios.get(
                    `${BASE_URL}/Image?start=${params.params.startingDate}&end=${params.params.endingDate}&page=${i}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                );
                if (recordedImagesResponse.status === 200) {
                    recordedImagesResponse.data.results.forEach((image) => {
                        recorderedImages.push(image);
                    });
                }
                i++;
            }
            yield put({ type: SET_RECORDED_IMAGES, data: recorderedImages });
        }
    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }
        else {
            yield put({ type: SET_RECORDED_IMAGES, data: [] });
        }
    }
}

export function* getWeatherSaga() {
    try {
        if (!localStorage.getItem('weatherData') || !localStorage.getItem('lastUpdated')) {
            console.log('no weather data and timestamp');
            let getWeatherResponse = yield axios.get(WEATHER_API, {
                headers: {
                    Authorization: ``
                }
            });
            yield put({
                type: SET_WEATHER_DATA,
                data: getWeatherResponse.data.data.timelines[0].intervals[0].values
            });

            localStorage.setItem('weatherData', JSON.stringify(getWeatherResponse.data.data.timelines[0].intervals[0].values));
            localStorage.setItem('lastUpdated', Date());
        }

        else {
            let now = moment(Date());
            let prev = moment(localStorage.getItem('lastUpdated'));
            let difference = now.diff(prev, "minutes");
            if (difference > 60) {
                console.log('stale data');
                let getWeatherResponse = yield axios.get(WEATHER_API, {
                    headers: {
                        Authorization: ``
                    }
                });
                yield put({
                    type: SET_WEATHER_DATA,
                    data: getWeatherResponse.data.data.timelines[0].intervals[0].values
                });
                localStorage.setItem('weatherData', JSON.stringify(getWeatherResponse.data.data.timelines[0].intervals[0].values));
                localStorage.setItem('lastUpdated', Date());
            }
            else {
                console.log('using non stale data');
                yield put({ type: SET_WEATHER_DATA, data: JSON.parse(localStorage.getItem('weatherData')) });
            }
        }
    } catch (e) {
        console.log(e);
        yield put({ type: SET_WEATHER_DATA, data: null });
    }
}
