import { StyleSheet } from 'aphrodite';
import {backgroundWhite, ctaDisabled, ctaHover, primaryActionColor, titleBlack, white} from './colors';
import authenticationBackground from './assets/authenticationBackground.svg';

export const globalStyles = StyleSheet.create({
  authenticationBg: {
    backgroundImage: `url(${authenticationBackground})`,
    backgroundSize: 'cover',
    height: '100vh',
  },
  secondary: {
    color: 'var(--light-btn-primary-base)',
  },
  lightBgColor: {
    backgroundColor: 'var(--light-bg-color-1)',
  },
  darkBgColor: {
    backgroundColor: 'var(--bg-color-1-dark-hover)',
  },

  darkBtnPrimaryColor: {
    backgroundColor: 'var(--dark-btn-primary)',
  },

  darkBtnPrimaryFontColor: {
    color: 'var(--dark-bg-color)',
  },

  standardCTAButton: {
    borderRadius: '2rem',
    fontWeight: 600,
    border: '0',
    height: '3rem',
    boxShadow: 'none',
    ':hover': {},
    transition: 'background-color .5s',
    transitionDuration: '.5s',
  },
  width100: {
    width: '100%',
  },
  width95: {
    width: '95%',
  },
  width90: {
    width: '90%',
  },
  width80: {
    width: '80%',
  },
  width70: {
    width: '70%',
  },
  width50: {
    width: '50%',
  },
  height80: {
    height: '80%',
  },
  width10: {
    width: '10%',
  },
  width20: {
    width: '20%',
  },
  width30: {
    width: '30%',
  },
  width40: {
    width: '40%',
  },
  imgSize: {
    height: 14,
  },
  //   margin
  mt_20: {
    marginTop: 20,
  },
  mb_20: {
    marginBottom: 20,
  },
  mt_4: {
    marginTop: 4,
  },
  mt_10: {
    marginTop: 10,
  },
  mb_10: {
    marginBottom: 10,
  },
  //   margin_em
  mt_4em: {
    marginTop: '4em',
  },
  mt_3em: {
    marginTop: '3em',
  },
  mr_10: {
    marginRight: 10,
  },
  mb_3em: {
    marginBottom: '3.5em',
  },
  mb_2em: {
    marginBottom: '2em',
  },
  mb_6em: { marginBottom: '6em' },
  mb_4em: {
    marginBottom: '4em',
  },
  ml_4: {
    marginLeft: 4,
  },
  ml_10: {
    marginLeft: 10,
  },
  ml_15: { marginLeft: 15 },
  ml_20: {
    marginLeft: 20,
  },
  ml_40: {
    marginLeft: 40,
  },
  ml_50: {
    marginLeft: 50,
  },
  mt_1em: {
    marginTop: '1em',
  },
  mt_2em: {
    marginTop: '2em',
  },
  mb_4: {
    marginBottom: 4,
  },
  //   padding
  p_30: {
    padding: 30,
  },
  p_60: {
    padding: 60,
  },
  p_120: {
    padding: 120,
  },
  pt_5: {
    paddingTop: 5,
  },
  pl_5: {
    paddingLeft: 5,
  },
  pb_5: {
    paddingBottom: 5,
  },
  pr_5: {
    paddingRight: 5,
  },
  pl_10: {
    paddingLeft: 10,
  },
  pr_10: {
    paddingRight: 10,
  },
  pb_10: {
    paddingBottom: 10,
  },
  pt_10: {
    paddingTop: 10,
  },
  pr_30: {
    paddingRight: 30,
  },
  pl_30: {
    paddingLeft: 30,
  },
  p_10: {
    padding: 10,
  },
  p_20: {
    padding: 20,
  },
  pb_2em: {
    paddingBottom: '2em',
  },
  pt_2em: {
    paddingTop: '2em',
  },
  pb_5em: {
    paddingBottom: '5em',
  },
  pb_4em: {
    paddingBottom: '4em',
  },
  pt_3em: {
    paddingTop: '3em',
  }, //   Hover
  hoverCursorPointer: {
    ':hover': {
      cursor: 'pointer',
    },
  },
  //   flex
  displayFlex: {
    display: 'flex',
  },
  displayNone: {
    display: 'none',
  },
  flexDirectionCol: {
    flexDirection: 'column',
  },
  flexJustifyCenter: {
    justifyContent: 'center',
  },
  flexJustifyEnd: {
    justifyContent: 'flex-end',
  },
  flexJustifySpaceBetween: {
    justifyContent: 'space-between',
  },
  flexAlignItemCenter: {
    alignItems: 'center',
  },
  flexAlignItemEnd: {
    alignItems: 'flex-end',
  },
  flexAlignBaseline: {
    alignItems: 'baseline',
  },
  hrLight: {
    backgroundColor: 'var(--light-bg-color-2)',
  },
  hrDark: {
    backgroundColor: 'var(--bg-color-1-dark-hover)',
  },
  underline: {
    textDecoration: 'underline',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  textAlignLeft: {
    textAlign: 'left',
  },
  // fontweight
  fontWeight700: {
    fontWeight: 700,
  },
  fontWeight600: {
    fontWeight: 600,
  },
  height10em: {
    height: '5em',
  },
  colorWhite: {
    color: 'white',
  },
  buttonStandard: {
    backgroundColor: primaryActionColor,
    color: white,
    height: 48,
    borderRadius: 10,
    ':hover': {
      backgroundColor: ctaHover,
    },
  },

  buttonStandardCancel: {
    backgroundColor: backgroundWhite,
    color: titleBlack,
    height: 48,
    borderRadius: 10,
    ':hover': {
      border: '0 0 4px 4px #000'
    },
  },

  buttonStandardNav: {
    textTransform: 'capitalize',
    backgroundColor: primaryActionColor,
    marginLeft: 20,
    color: white,
    height: 40,
    borderRadius: 10,
    ':hover': {
      backgroundColor: ctaHover,
    },
  },

  buttonStandardFilter: {
    backgroundColor: primaryActionColor,
    color: white,
    height: '2.5em',
    width: '8em',
    padding: '0 .5em 0 .5em',
    borderRadius: 10,
    ':hover': {
      backgroundColor: ctaHover,
    },
  },

  buttonStandardFilterMobile: {
    backgroundColor: primaryActionColor,
    color: white,
    height: '2.5em',
    width: '2.5em',
    padding: '0 .5em 0 .5em',
    borderRadius: 10,
    ':hover': {
      backgroundColor: ctaHover,
    },
  },

  buttonStandardDisabled: {
    backgroundColor: ctaDisabled,
    opacity: 0.5,
  },
});
