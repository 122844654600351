import axios from 'axios';
import { put } from 'redux-saga/effects';
import {
    DOWNLOAD_PDF_ACTION_COMPLETE,
    EVENT_FEEDBACK_ACTION_COMPLETED,
    SET_EVENTS_ACTION,
    SHOW_TOAST
} from '../actions/types';
import { checkSession, getAccessToken } from './authSagas';

const BASE_URL = process.env.REACT_APP_BASE_URL;


export const getRequestResource = () => {
    let arr = [
        "https://conxai.el.r.appspot.com"
    ];
    let serial = Math.floor(1000 + Math.random() * 9000);
    serial = serial % 2;
    let srv = arr[serial];
    return srv;
}

export function* getEventsSaga(params) {
    try {
        let event = '';
        if (params.params.path.length > 0) {
            if (params.params.path.includes('hse')) event = 'hse';
            if (params.params.path.includes('workforce')) event = 'workforce';
            if (params.params.path.includes('equipment')) event = 'equipment';
            if (params.params.path.includes('delivery')) event = 'delivery';
        }
        let data = {};
        data.owner = localStorage.getItem('email');
        data.eventsType = event;
        data.startDate = localStorage.getItem('start');
        data.endDate = localStorage.getItem('end');
        // let getEventsCacheResponse = yield axios.post(`${getRequestResource()}/event/get`, data);
        // if (getEventsCacheResponse.data.length < 1) {
        let eventsType = '';
        if (params.params.path.length > 0) {
            if (params.params.path.includes('hse')) eventsType = '&type=HSE';
            if (params.params.path.includes('workforce')) eventsType = '&object=Person';
            if (params.params.path.includes('equipment')) eventsType = '&object=Equipment';
            if (params.params.path.includes('delivery')) eventsType = '&type=Entry';
        }
        const token = yield getAccessToken()
        const getEventsResponse = yield axios.get(
            `${BASE_URL}/Event?start=${params.params.startingDate}&end=${params.params.endingDate}${eventsType}&page=${params.params.page}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (getEventsResponse.data.results.length > 0) {
            yield put({ type: SET_EVENTS_ACTION, data: getEventsResponse.data.results });
            // let eventsData = {};
            // eventsData.owner = localStorage.getItem('email');
            // eventsData.eventsType = event;
            // eventsData.startDate = localStorage.getItem('start');
            // eventsData.endDate = localStorage.getItem('end');
            // eventsData.results = getEventsResponse.data.results;
            //
            // yield axios.post(`https://conxai.el.r.appspot.com/event/add`, eventsData).then((r) => {
            //     if (r.status === 200) console.log('cache updated');
            // });
        } else {
            yield put({ type: SET_EVENTS_ACTION, data: [] });
        }
        // }
        // if (getEventsCacheResponse.data.length > 0) {
        //     yield put({type: SET_EVENTS_ACTION, data: getEventsCacheResponse.data[0].results});
        // }
    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }
        yield put({ type: SET_EVENTS_ACTION, data: [] });
    }
}

export function* eventFeedbackSaga(params) {
    try {
        const token = yield getAccessToken()
        let eventFeedbackResponse = yield axios.post(`${BASE_URL}/EventFeedback/`, params.params.data,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );
        if (eventFeedbackResponse.status === 200) {
            yield put({
                type: SHOW_TOAST,
                data: {
                    showToast: true,
                    toastMessage: params.params.data.positive ? 'Thanks for your feedback.' : 'Your feedback is received, we are looking into it.',
                    toastType: 'success'
                }
            });

            yield put({ type: EVENT_FEEDBACK_ACTION_COMPLETED });
        }

    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }
        yield put({
            type: SHOW_TOAST,
            data: {
                showToast: true,
                toastMessage: 'Something isn\'t working. Please try again later.',
                toastType: 'error'
            }
        });
        yield put({ type: EVENT_FEEDBACK_ACTION_COMPLETED });
    }
}

export function* downloadPDFSaga(params) {
    try {
        let eventsType = '';
        if (params.params.path.length > 0) {
            if (params.params.path.includes('hse')) eventsType = '&type=HSE';
            if (params.params.path.includes('workforce')) eventsType = '&object=Person';
            if (params.params.path.includes('equipment')) eventsType = '&object=Equipment';
            if (params.params.path.includes('delivery')) eventsType = '&type=Entry';
        }

        const token = yield getAccessToken()

        let downloadPDFResponse = yield axios.get(
            `${BASE_URL}/pdf/${params.params.selectedTab === 'List' ? 'Events' : 'Flagging'}?start=${localStorage.getItem('start')}&end=${localStorage.getItem('end')}${eventsType}`,
            {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },

            });
        if (downloadPDFResponse.status === 200) {

            const blob = new Blob([downloadPDFResponse.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `CONXAI_Dashboard_Events_${localStorage.getItem('start')}_${localStorage.getItem('end')}.pdf`;
            link.click();
            yield put({ type: DOWNLOAD_PDF_ACTION_COMPLETE });
        }
        else {
            yield put({
                type: SHOW_TOAST,
                data: {
                    showToast: true,
                    toastMessage: 'Cannot download file at the moment, please try again later.',
                    toastType: 'error'
                }
            });

            yield put({ type: DOWNLOAD_PDF_ACTION_COMPLETE });
        }
    }

    catch {
        yield put({
            type: SHOW_TOAST,
            data: {
                showToast: true,
                toastMessage: 'Cannot download file at the moment, please try again later.',
                toastType: 'error'
            }
        });
        yield put({ type: DOWNLOAD_PDF_ACTION_COMPLETE });
    }
}
