import {GET_PROGRESS, SET_PROGRESS} from "../actions/types";

const initialState = {
    progressTrackerLoading: true,
    progressData: null,
};

export default function (state = initialState, action) {

    switch (action.type) {

        case GET_PROGRESS:
            return {
                ...state,
                progressTrackerLoading: true,
            }

        case SET_PROGRESS:
            return {
                ...state,
                progressTrackerLoading: false,
                progressData: action.data,
            }

        default:
            return state;
    }
}
