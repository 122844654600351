import moment from 'moment';
import {
    GET_ALERTS_COUNT,
    GET_DELIVERIES_COUNT,
    GET_EVENTS_COUNT,
    GET_PEOPLE_COUNT,
    GET_RECORDED_IMAGES,
    SET_ALERTS_CHART_DATA_X,
    SET_ALERTS_CHART_DATA_Y,
    SET_ALERTS_COUNT,
    SET_DATE_RANGE_ACTION,
    SET_DELIVERIES_COUNT,
    SET_EVENTS_COUNT,
    SET_PEOPLE_COUNT,
    SET_PEOPLE_CHART_DATA_X,
    SET_PEOPLE_CHART_DATA_Y,
    SET_RECORDED_IMAGES,
    SET_DATE_RANGE_CALENDAR_ACTION,
    SET_PAGINATION,
    UPDATE_PAGE_NUMBER,
    TRAFFIC_COUNT,
    VEHICLE_FLAGGING_DATA,
    GET_FLAGGING_COUNT,
    SET_FLAGGING_COUNT,
    UPDATE_SEARCH_TERM,
    SET_RECORDED_IMAGES_COUNT,
    SET_RECORDED_IMAGES_PAGE_COUNT,
    UPDATE_OBJECT_DISPLAY,
    SET_DELIVERIES_DELTA_COUNT,
    SET_DATE_DIFFERENCE,
    SET_PEOPLE_COUNT_DELTA, SET_ALERTS_COUNT_DIFF, SET_EVENTS_COUNT_DIFF, GET_WEATHER_DATA, SET_WEATHER_DATA,
} from '../actions/types';

const initialState = {
    startingDate: localStorage.getItem('start'),
    endingDate: localStorage.getItem('end'),
    endingDateEvents: null,
    peopleCountLoading: true,
    peopleCount: null,
    peopleCountDiff: null,
    peopleChartDataX: null,
    peopleChartDataY: null,
    alertsCountLoading: true,
    alertsCount: null,
    alertsChartDataX: null,
    alertsChartDataY: null,
    eventsCountLoading: true,
    eventsCount: null,
    deliveriesCountLoading: true,
    deliveriesCount: null,
    deliveriesCountDelta: null,
    recordedImagesLoading: true,
    recordedImages: [],
    currentPage: 1,
    nextPage: null,
    pageCount: null,
    trafficDataLoading: true,
    trafficData: null,
    vehicleFlagging: [],
    flaggingChartLoading: true,
    flaggingChartData: null,
    searchTerm: '',
    recordedImagesCount: null,
    recordedImagesPageCount: null,
    objectView: 'list',
    alertsCountDiff: null,
    eventsCountDiff: null,
    daysDifference: null,
    loadingWeatherData: true,
    weatherData: null,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_DATE_RANGE_ACTION:
            if (!localStorage.getItem('defaultWindow')) {
                localStorage.setItem('defaultWindow', 7);
            }
            return {
                ...state,
                startingDate: localStorage.getItem('start'),
                endingDate: localStorage.getItem('end'),
                endingDateEvents: localStorage.getItem('end'),
            };

        case SET_DATE_RANGE_CALENDAR_ACTION:
            localStorage.setItem('start', moment(action.params.startingDate).format('YYYY-MM-DD'));
            localStorage.setItem('end', moment(action.params.endingDate).format('YYYY-MM-DD'));
            return {
                ...state,
                startingDate: localStorage.getItem('start'),
                endingDate: localStorage.getItem('end'),
                endingDateEvents: localStorage.getItem('end'),
            };

        case GET_PEOPLE_COUNT:
            return {
                ...state,
                peopleCountLoading: true,
            };

        case SET_PEOPLE_COUNT:
            return {
                ...state,
                peopleCountLoading: false,
                peopleCount: action.data,
            };

        case SET_PEOPLE_COUNT_DELTA:
            return {
                ...state,
                peopleCountDiff: action.data,
            }

        case SET_PEOPLE_CHART_DATA_X:
            return {
                ...state,
                peopleChartDataX: action.data,
            };

        case SET_PEOPLE_CHART_DATA_Y:
            return {
                ...state,
                peopleChartDataY: action.data,
            };

        case GET_ALERTS_COUNT:
            return {
                ...state,
                alertsCountLoading: true,
            };

        case SET_ALERTS_COUNT:
            return {
                ...state,
                alertsCountLoading: false,
                alertsCount: action.data,
            };

        case SET_ALERTS_COUNT_DIFF:
            return {
                ...state,
                alertsCountDiff: action.data,
            };

        case SET_ALERTS_CHART_DATA_X:
            return {
                ...state,
                alertsChartDataX: action.data,
            };

        case SET_ALERTS_CHART_DATA_Y:
            return {
                ...state,
                alertsChartDataY: action.data,
            };

        case TRAFFIC_COUNT:
            return {
                ...state,
                trafficDataLoading: false,
                trafficData: action.data,
            };

        case VEHICLE_FLAGGING_DATA:
            return {
                ...state,
                vehicleFlagging: action.data,
            }

        case GET_EVENTS_COUNT:
            return {
                ...state,
                eventsCountLoading: true,
            };

        case SET_EVENTS_COUNT:
            return {
                ...state,
                eventsCountLoading: false,
                eventsCount: action.data,
            };

        case SET_EVENTS_COUNT_DIFF:
            return {
                ...state,
                eventsCountDiff: action.data,
            }

        case GET_DELIVERIES_COUNT:
            return {
                ...state,
                deliveriesCountLoading: true,
                trafficDataLoading: true,
            };

        case SET_DELIVERIES_COUNT:
            return {
                ...state,
                deliveriesCountLoading: false,
                deliveriesCount: action.data,
            };

        case SET_DELIVERIES_DELTA_COUNT:
            return {
                ...state,
                deliveriesCountDelta: action.data,
            }

        case GET_RECORDED_IMAGES:
            return {
                ...state,
                recordedImagesLoading: true,
                recordedImages: [],
            };

        case SET_RECORDED_IMAGES:
            let temp = [...state.recordedImages];
            for(let i=0; i<action.data.length; i++) {
                temp.push(action.data[i]);
            }
            return {
                ...state,
                recordedImagesLoading: false,
                recordedImages: temp,
            };

        case SET_RECORDED_IMAGES_PAGE_COUNT:
            return {
                ...state,
                recordedImagesPageCount: !state.recordedImagesPageCount && action.data,
            }

        case SET_RECORDED_IMAGES_COUNT:
            return {
                ...state,
                recordedImagesCount: action.data,
            }

        case SET_PAGINATION:
            return {
                ...state,
                currentPage: action.data.currentPage,
                nextPage: action.data.nextPage,
                pageCount: action.data.pageCount,
            };

        case UPDATE_PAGE_NUMBER:
            return {
                ...state,
                currentPage: action.params,
            };

        case GET_FLAGGING_COUNT:
            return {
                ...state,
                flaggingChartLoading: true,
            };

        case SET_FLAGGING_COUNT:
            return {
                ...state,
                flaggingChartLoading: false,
                flaggingChartData: action.data,
            }

        case UPDATE_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.params.searchTerm,
            }

        case UPDATE_OBJECT_DISPLAY:
            return {
                ...state,
                objectView: action.params,
            }
        case SET_DATE_DIFFERENCE:
            return {
                ...state,
                daysDifference: action.data,
            }

        case GET_WEATHER_DATA:
            return {
                ...state,
                loadingWeatherData: true,
            }

        case SET_WEATHER_DATA:
            return {
                ...state,
                loadingWeatherData: false,
                weatherData: action.data,
            }

        default:
            return state;
    }
}
