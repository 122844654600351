import axios from "axios";
import { put } from "redux-saga/effects";
import { SET_PROGRESS } from "../actions/types";
import { checkSession, getAccessToken } from "./authSagas";

const BASE_URL = process.env.REACT_APP_BASE_URL;

axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('access-token')}`;

export function* getProgressSaga(params) {
    try {
        const token = yield getAccessToken()
        if (params.params.siteCameras[0]) {
            let camera = params.params.siteCameras[0];
            let getProgressResponse = yield axios.get(`${BASE_URL}/progress?start=${params.params.startingDate}&end=${params.params.endingDate}&cameraId=${camera}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (getProgressResponse.status === 200)
                yield put({ type: SET_PROGRESS, data: getProgressResponse.data });
            else
                yield put({ type: SET_PROGRESS, data: null });
        }
    } catch (e) {
        if (e?.response?.status === 401 || e?.response?.status === 403 || e?.message === 'Network Error') {
            checkSession();
        }
        yield put({ type: SET_PROGRESS, data: null });
    }
}
