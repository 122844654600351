import {
    ADD_REMOVE_COMPONENT_COCKPIT,
    REMOVE_COMPONENT_COCKPIT,
    REQUEST_LOG_IN,
    REQUEST_LOG_IN_FAILED,
    REQUEST_SIGN_UP, SET_PREFERENCES
} from '../actions/types';

const initialState = {
    loggingIn: false,
    signingUp: false,
    sendingRecoveryEmail: false,
    authErrorMessage: '',
    userPreferences: JSON.parse(localStorage.getItem('userPrefs')),
};

export default function (state = initialState, action) {
    switch (action.type) {
        case REQUEST_LOG_IN:
            return {
                ...state,
                loggingIn: true,
            };

        case REQUEST_SIGN_UP:
            return {
                ...state,
                signingUp: true,
            };

        case REQUEST_LOG_IN_FAILED:
            return {
                ...state,
                loggingIn: false,
                signingUp: false,
                authErrorMessage: action.data,
            };

        case SET_PREFERENCES:
            return {
                ...state,
                userPreferences: JSON.parse(localStorage.getItem('usePrefs')),
            }

        case REMOVE_COMPONENT_COCKPIT:
            let temp = JSON.parse(localStorage.getItem('userPrefs'));
            if (action.params === 'People') {
                temp.peopleMetric = false;
            }

            if (action.params === 'Weather') {
                temp.weatherWidget = false;
            }

            if (action.params === 'Alerts') {
                temp.alertsMetric =  false;
            }

            if (action.params === 'Deliveries') {
                temp.deliveriesMetric =  false;
            }

            if (action.params === 'Events') {
                temp.activeEventsMetric = false;
            }

            if (action.params === 'Images') {
                temp.imageDisplay = false
            }

            if (action.params === 'PeopleGraph') {
                temp.peopleGraph = false
            }

            if (action.params === 'AlertsGraph') {
                temp.alertsGraph = false
            }

            if (action.params === 'Traffic') {
                temp.trafficGraph = false
            }

            if (action.params === 'Vehicle Flagging') {
                temp.vehicleFlagging = false
            }

            if (action.params === 'Vehicle Flagging Count') {
                temp.vehicleFlaggingCount = false;
            }
            localStorage.setItem('userPrefs', JSON.stringify(temp));
            return {
                ...state,
                 userPreferences: temp,
            };

        case ADD_REMOVE_COMPONENT_COCKPIT:
            let temp2 = JSON.parse(localStorage.getItem('userPrefs'));
            if (action.params === 'People') {
                let val = temp2.peopleMetric;
                temp2.peopleMetric = !val;
            }

            if (action.params === 'Alerts') {
                let val = temp2.alertsMetric;
                temp2.alertsMetric = !val;
            }

            if (action.params === 'Weather') {
                let val = temp2.weatherWidget;
                temp2.weatherWidget = !val;
            }

            if (action.params === 'Deliveries') {
                let val = temp2.deliveriesMetric;
                temp2.deliveriesMetric = !val;
            }

            if (action.params === 'Events') {
                let val = temp2.activeEventsMetric;
                temp2.activeEventsMetric = !val;
            }

            if (action.params === 'Images') {
                let val = temp2.imageDisplay;
                temp2.imageDisplay = !val;
            }

            if (action.params === 'PeopleGraph') {
                let val = temp2.peopleGraph;
                temp2.peopleGraph = !val;
            }

            if (action.params === 'AlertsGraph') {
                let val = temp2.alertsGraph;
                temp2.alertsGraph = !val;
            }

            if (action.params === 'Traffic') {
                let val = temp2.trafficGraph;
                temp2.trafficGraph = !val;
            }

            if (action.params === 'Vehicle Flagging') {
                let val = temp2.vehicleFlagging;
                temp2.vehicleFlagging = !val;
            }

            if (action.params === 'Vehicle Flagging Count') {
                let val = temp2.vehicleFlaggingCount;
                temp2.vehicleFlaggingCount = !val;
            }
            localStorage.setItem('userPrefs', JSON.stringify(temp2));

            return {
                ...state,
                userPreferences: JSON.parse(localStorage.getItem('userPrefs')),
            };

        default:
            return state;
    }
}
