import {
    GET_ALL_COMMENTS,
    SET_ALL_COMMENTS,
    DELETE_COMMENT_ACTION,
    UPDATE_ALL_COMMENTS,
    SET_ALL_COMMENTS_COUNT,
    UPDATE_COMMENT,
    UPDATE_COMMENT_ACTION,
    SET_ALL_COMMENTS_PAGE
} from "../actions/types";

const initialState = {
    allCommentsLoading: true,
    allComments: [],
    nextPage: null,
    pageCount: null,
    allCommentsCount: null,
    deletingComment: false,
};

export default function (state = initialState, action) {

    switch (action.type) {

        case GET_ALL_COMMENTS:
            return {
                ...state,
                allCommentsLoading: true,
            };

        case SET_ALL_COMMENTS:
            // let temp = [...state.allComments];
            // action.data.forEach((comment) => {
            //    temp.push(comment);
            // });
            return {
                ...state,
                allCommentsLoading: false,
                allComments: action.data
            }

            case SET_ALL_COMMENTS_PAGE:
            let temp = [...state.allComments];
            action.data.forEach((comment) => {
               temp.push(comment);
            });
            return {
                ...state,
                allCommentsLoading: false,
                allComments: temp
            }

        case DELETE_COMMENT_ACTION:
            return {
                ...state,
                deletingComment: true,
            }

        case UPDATE_COMMENT_ACTION:
            return {
                ...state,
                deletingComment: true,
            }

        case UPDATE_ALL_COMMENTS:
            let tempUpdate = [...state.allComments];
            tempUpdate.unshift(action.data);
            return {
                ...state,
                allComments: tempUpdate,
                deletingComment: false,
                allCommentsCount: state.allCommentsCount + 1
            }

        case SET_ALL_COMMENTS_COUNT:
            return {
                ...state,
                nextPage:  action.data.nextPage,
                pageCount:  action.data.pageCount,
                allCommentsCount: action.data.count,
            }

        case UPDATE_COMMENT:
            let tempDelete = [...state.allComments];
            let toUpdate = -1;
            for(let i=0; i<tempDelete.length; i++) {
                if(tempDelete[i].commentID === action.data.commentID) {
                    toUpdate = i;
                    break;
                }
            }
            tempDelete[toUpdate] = action.data;
            return {
                ...state,
                allComments: tempDelete,
                allCommentsCount: tempDelete.length,
                deletingComment: false,
            }

        default:
            return state;
    }
}
