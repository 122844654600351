// auth actions
export const REQUEST_LOG_IN = 'REQUEST_LOG_IN';
export const REQUEST_LOG_IN_FAILED = 'REQUEST_LOG_IN_FAILED';
export const REQUEST_SIGN_UP = 'REQUEST_SIGN_UP';
export const REMOVE_COMPONENT_COCKPIT = 'REMOVE_COMPONENT_COCKPIT';
export const ADD_REMOVE_COMPONENT_COCKPIT = 'ADD_REMOVE_COMPONENT_COCKPIT';
export const GET_USER_PREFERENCES = 'GET_USER_PREFERENCES';
export const SET_USER_PREFERENCES = 'SET_USER_PREFERENCES';
export const SET_PREFERENCES = 'SET_PREFERENCES';
export const UPDATE_USER_PREFERENCES = 'UPDATE_USER_PREFERENCES';

// controller actions
export const SET_DATE_DIFFERENCE = 'SET_DATE_DIFFERENCE';
export const SET_DATE_RANGE_ACTION = 'SET_DATE_RANGE_ACTION';
export const SET_DATE_RANGE_CALENDAR_ACTION = 'SET_DATE_RANGE_CALENDAR_ACTION';
export const GET_PEOPLE_COUNT = 'GET_PEOPLE_COUNT';
export const SET_PEOPLE_CHART_DATA_X = 'SET_PEOPLE_CHART_DATA_X';
export const SET_PEOPLE_CHART_DATA_Y = 'SET_PEOPLE_CHART_DATA_Y';
export const SET_PEOPLE_COUNT = 'SET_PEOPLE_COUNT';
export const SET_PEOPLE_COUNT_DELTA = 'SET_PEOPLE_COUNT_DELTA';
export const GET_ALERTS_COUNT = 'GET_ALERTS_COUNT';
export const SET_ALERTS_COUNT = 'SET_ALERTS_COUNT';
export const SET_ALERTS_COUNT_DIFF = 'SET_ALERTS_COUNT_DIFF';
export const SET_ALERTS_CHART_DATA_X = 'SET_ALERTS_CHART_DATA_X';
export const SET_ALERTS_CHART_DATA_Y = 'SET_ALERTS_CHART_DATA_Y';
export const TRAFFIC_COUNT = 'TRAFFIC_COUNT';
export const VEHICLE_FLAGGING_DATA = 'VEHICLE_FLAGGING_DATA';
export const GET_EVENTS_COUNT = 'GET_EVENTS_COUNT';
export const SET_EVENTS_COUNT = 'SET_EVENTS_COUNT';
export const SET_EVENTS_COUNT_DIFF = 'SET_EVENTS_COUNT_DIFF';
export const GET_DELIVERIES_COUNT = 'GET_DELIVERIES_COUNT';
export const SET_DELIVERIES_COUNT = 'SET_DELIVERIES_COUNT';
export const SET_DELIVERIES_DELTA_COUNT = 'SET_DELIVERIES_DELTA_COUNT';
export const GET_RECORDED_IMAGES = 'GET_RECORDED_IMAGES';
export const SET_RECORDED_IMAGES = 'SET_RECORDED_IMAGES';
export const SET_RECORDED_IMAGES_COUNT = 'SET_RECORDED_IMAGES_COUNT';
export const SET_RECORDED_IMAGES_PAGE_COUNT = 'SET_RECORDED_IMAGES_PAGE_COUNT';
export const GET_FLAGGING_COUNT = 'GET_FLAGGING_COUNT';
export const SET_FLAGGING_COUNT = 'SET_FLAGGING_COUNT';
export const GET_SITE_CAMERAS = 'GET_SITE_CAMERAS';
export const SET_SITE_CAMERAS = 'SET_SITE_CAMERAS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER';
export const UPDATE_SELECTED_CAMERAS = 'UPDATE_SELECTED_CAMERAS';
export const CLEAR_SELECTED_CAMERAS = 'CLEAR_SELECTED_CAMERAS';
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM';
export const UPDATE_OBJECT_DISPLAY = 'UPDATE_OBJECT_DISPLAY';
export const GET_WEATHER_DATA = 'GET_WEATHER_DATA';
export const SET_WEATHER_DATA = 'SET_WEATHER_DATA';

// events actions
export const GET_EVENTS_ACTION = 'GET_EVENTS_ACTION';
export const SET_EVENTS_ACTION = 'SET_EVENTS_ACTION';
export const UPDATE_CURRENT_EVENT_ACTION = 'UPDATE_CURRENT_EVENT_ACTION';
export const SHOW_EVENT_ACTION = 'SHOW_EVENT_ACTION';
export const HIDE_EVENT_ACTION = 'HIDE_EVENT_ACTION';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const DOWNLOAD_PDF_ACTION = 'DOWNLOAD_PDF_ACTION';
export const DOWNLOAD_PDF_ACTION_COMPLETE = 'DOWNLOAD_PDF_ACTION_COMPLETE';
export const HANDLE_FULL_SCREEN_ACTION = 'HANDLE_FULL_SCREEN_ACTION';
export const EVENT_FEEDBACK_ACTION = 'EVENT_FEEDBACK_ACTION';
export const EVENT_FEEDBACK_ACTION_COMPLETED = 'EVENT_FEEDBACK_ACTION_COMPLETED';
export const PLAY_WEBM = 'PLAY_WEBM';
export const PAUSE_WEBM = 'PAUSE_WEBM';

// objects actions
export const GET_OBJECTS_ACTION = 'GET_OBJECTS_ACTION';
export const SET_OBJECTS_ACTION = 'SET_OBJECTS_ACTION';
export const SHOW_OBJECT_ACTION = 'SHOW_OBJECT_ACTION';
export const HIDE_OBJECT_ACTION = 'HIDE_OBJECT_ACTION';
export const SHOW_OBJECT_ACTION_SUCCESS = 'SHOW_OBJECT_ACTION_SUCCESS';
export const UPDATE_CURRENT_OBJECT_ACTION = 'UPDATE_CURRENT_OBJECT_ACTION';
export const UPDATE_CURRENT_OBJECT_COMMENT_ID = 'UPDATE_CURRENT_OBJECT_COMMENT_ID';

// progress-tracker actions
export const GET_PROGRESS = 'GET_PROGRESS';
export const SET_PROGRESS = 'SET_PROGRESS';

// toast actions
export const SHOW_TOAST = 'SHOW_TOAST';
export const HIDE_TOAST = 'HIDE_TOAST';
export const SHOW_WAITING_TOAST = 'SHOW_WAITING_TOAST';

// comments actions
export const GET_ALL_COMMENTS = 'GET_ALL_COMMENTS';
export const SET_ALL_COMMENTS = 'SET_ALL_COMMENTS';

export const GET_ALL_COMMENTS_PAGE = 'GET_ALL_COMMENTS_PAGE';
export const SET_ALL_COMMENTS_PAGE = 'SET_ALL_COMMENTS_PAGE';

export const POST_COMMENT_ACTION = 'POST_COMMENT_ACTION';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const UPDATE_ALL_COMMENTS = 'UPDATE_ALL_COMMENTS';

export const SET_ALL_COMMENTS_COUNT = 'SET_ALL_COMMENTS_COUNT';

export const DELETE_COMMENT_ACTION = 'DELETE_COMMENT_ACTION';

export const UPDATE_COMMENT_ACTION = 'UPDATE_COMMENT_ACTION';
