import axios from 'axios';
import { put } from 'redux-saga/effects';
import moment from "moment";
import { REQUEST_LOG_IN_FAILED, SET_PREFERENCES, SHOW_TOAST } from '../actions/types';
import * as Amplify from 'aws-amplify';


Amplify.Amplify.configure({
    Auth: {
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        region: process.env.REACT_APP_REGION,
        userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
        mandatorySignIn: true,
        oauth: {
            domain: `${process.env.REACT_APP_HOSTED_UI_DOMAIN}`,
            redirectSignOut: `${process.env.REACT_APP_HOST_URL}/sign-out`,
            redirectSignIn: `${process.env.REACT_APP_HOST_URL}`,
            responseType: 'code',
            scope: ['email', 'openid']
        }
    },
});


export const AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const INDEXER_URL_V2 = process.env.REACT_APP_INDEXER_URL_V2;
export const INDEXER_URL_V2_V2 = process.env.REACT_APP_INDEXER_URL_V2_V2;
export const INDEXER_URL_V2_US = process.env.REACT_APP_INDEXER_URL_V2_US

export function isNeedLogout() {
    return localStorage.getItem('email') &&
        !localStorage.getItem('email').includes('webcor') ||
        !localStorage.getItem('email')
}

export function* requestLogInSaga(params) {
    try {
        const requestLogInResponse = yield axios.post(`${AUTH_URL}/login/`, params.params);
        if (requestLogInResponse.status === 200) {
            localStorage.setItem('refresh', requestLogInResponse.data.refresh);
            localStorage.setItem('access-token', requestLogInResponse.data.access);
            localStorage.setItem('email', requestLogInResponse.data.email);
            localStorage.setItem('firstName', requestLogInResponse.data.firstName);
            localStorage.setItem('lastName', requestLogInResponse.data.lastName);
            localStorage.setItem('profileImageUrl', requestLogInResponse.data.profileImageUrl);
            localStorage.setItem('companyLogoUrl', requestLogInResponse.data.companyLogoUrl);
            if (!localStorage.getItem('defaultWindow')) {
                localStorage.setItem('defaultWindow', 7);
                localStorage.setItem('start', moment(Date.now()).subtract(localStorage.getItem('defaultWindow'), 'days').format('YYYY-MM-DD'));
                localStorage.setItem('end', moment(Date.now()).format('YYYY-MM-DD'));
            }
            let config = {
                peopleMetric: true,
                alertsMetric: true,
                deliveriesMetric: true,
                activeEventsMetric: true,
                imageDisplay: true,
                peopleGraph: true,
                alertsGraph: true,
                weatherWidget: true,
                trafficGraph: true,
                vehicleFlagging: true,
                vehicleFlaggingCount: true,
                peopleMetricX: 0,
                peopleMetricY: 0,
                alertsMetricX: 0,
                alertsMetricY: 0,
                eventsMetricX: 0,
                eventsMetricY: 0,
                deliveriesMetricX: 0,
                deliveriesMetricY: 0,
                weatherWidgetX: 0,
                weatherWidgetY: 0,
                imageDisplayX: 0,
                imageDisplayY: 0,
                vehicleFlaggingX: 0,
                vehicleFlaggingY: 0,
                peopleGraphX: 0,
                peopleGraphY: 0,
                alertsGraphX: 0,
                alertsGraphY: 0,
                flaggingGraphX: 0,
                flaggingGraphY: 0,
                stackedGraphX: 0,
                stackedGraphY: 0,
            };
            localStorage.setItem('userPrefs', JSON.stringify(config));
            // let setPreferencesResponse = yield axios.post(`${AUTH_URL}/user/preferences/`, {configuration: config}, {
            //     headers: {
            //         Authorization: `Bearer ${token}`,
            //     },
            // });
            // if (setPreferencesResponse.status === 200) {
            window.location = '/progress-tracker';
            // }
        } else {
            yield put({ type: REQUEST_LOG_IN_FAILED, data: 'Something went wrong, please try again later.' });
        }
    } catch (e) {
        yield put({ type: REQUEST_LOG_IN_FAILED, data: 'Incorrect Email ID or Password.' });
    }
}

export function* setPreferencesSaga(params) {
    try {
        if (params.params)
            console.log(params);
        else {
            const token = yield getAccessToken()
            let getPreferencesResponse = yield axios.get(`${AUTH_URL}/user/preferences/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            yield put({ type: SET_PREFERENCES, data: getPreferencesResponse.data })
        }
    } catch (e) {

    }
}

export function* requestSignUpSaga(params) {
    try {
        let requestSignUpResponse = yield axios.post(`${AUTH_URL}/register/`, params.params, {
            headers: {
                Authorization: ''
            }
        });
        if (requestSignUpResponse.status !== 201) {
            yield put({
                type: SHOW_TOAST,
                data: {
                    showToast: true,
                    toastMessage: 'Your domain is unapproved, please contact support for details.',
                    toastType: 'error'
                }
            });
            yield put({ type: REQUEST_LOG_IN_FAILED });
        }
        if (requestSignUpResponse.status === 201) {
            localStorage.setItem('refresh', requestSignUpResponse.data.refresh);
            localStorage.setItem('access-token', requestSignUpResponse.data.access);
            localStorage.setItem('email', requestSignUpResponse.data.email);
            localStorage.setItem('firstName', requestSignUpResponse.data.firstName);
            localStorage.setItem('lastName', requestSignUpResponse.data.lastName);
            localStorage.setItem('profileImageUrl', requestSignUpResponse.data.profileImageUrl);
            if (!localStorage.getItem('defaultWindow')) {
                localStorage.setItem('defaultWindow', 7);
                localStorage.setItem('start', moment(Date.now()).subtract(localStorage.getItem('defaultWindow'), 'days').format('YYYY-MM-DD'));
                localStorage.setItem('end', moment(Date.now()).format('YYYY-MM-DD'));
            }
            let config = {
                peopleMetric: true,
                alertsMetric: true,
                deliveriesMetric: true,
                activeEventsMetric: true,
                imageDisplay: true,
                peopleGraph: true,
                alertsGraph: true,
                trafficGraph: true,
                vehicleFlagging: true,
                vehicleFlaggingCount: true,
            };
            localStorage.setItem('userPrefs', JSON.stringify(config));
            // let setPreferencesResponse = yield axios.post(`${AUTH_URL}/user/preferences/`, {configuration: config}, {
            //     headers: {
            //         Authorization: `Bearer ${token}`,
            //     },
            // });
            // if (setPreferencesResponse.status === 200) {
            window.location = '/cockpit';
        }
    }
    catch (e) {
        console.log('error ', e.response);
        if (e.response.status === 400) {
            yield put({
                type: SHOW_TOAST,
                data: {
                    showToast: true,
                    toastMessage: 'Company domain not registered, please create an account with registered domain.',
                    toastType: 'error'
                }
            });
            yield put({ type: REQUEST_LOG_IN_FAILED });
        }

        if (e.response.status >= 500) {
            yield put({
                type: SHOW_TOAST,
                data: {
                    showToast: true,
                    toastMessage: 'Something went wrong, please try again later.',
                    toastType: 'error'
                }
            });
            yield put({ type: REQUEST_LOG_IN_FAILED });
        }
    }
}

export function* removeComponentSaga(params) {
}

export function* updateUserPreferencesSaga(params) {
}

export const checkSession = async () => {
    Amplify.Auth.currentSession()
        .then(async session => {
            if (!session.isValid()) {
                await logOutSession()
            }
        })
        .catch(async (err) => {
            window.location = `${process.env.REACT_APP_HOST_URL}/sign-out`
        });
}

export const logOutSession = async () => {
    localStorage.setItem('email', '')
    Amplify.Auth.signOut()
}

export const getAccessToken = async () => {
    try {
        const session = await Amplify.Auth.currentSession()
        if (session && session.isValid()) {
            return session.getIdToken().getJwtToken()
        } else {
            await logOutSession()
        }
    } catch (error) {
        window.location = `${process.env.REACT_APP_HOST_URL}/sign-out`
    }
}