import {
  GET_OBJECTS_ACTION, HIDE_OBJECT_ACTION,
  SET_OBJECTS_ACTION, SHOW_OBJECT_ACTION, SHOW_OBJECT_ACTION_SUCCESS,
  UPDATE_CURRENT_OBJECT_ACTION, UPDATE_CURRENT_OBJECT_COMMENT_ID,
} from '../actions/types';

const initialState = {
  objectsLoading: true,
  objectArrLoading: true,
  objectsArr: [],
  objectArr: [],
  currentObjectID: 0,
  currentObjectIDX: null,
  showObject: false,
  objectName: '',
  currentObjects: [],
  selectedTab: 'Timeline',
  fullScreen: false,
  related: [],
  objectCommentID: 0,
};

export default function (state = initialState, action) {

  switch (action.type) {

    case GET_OBJECTS_ACTION:
      return {
        ...state,
        objectsLoading: true,
      };

    case SET_OBJECTS_ACTION:
      return {
        ...state,
        showObject: false,
        objectsLoading: false,
        objectArrLoading: false,
        objectsArr: action.data,
      };

    case UPDATE_CURRENT_OBJECT_ACTION:
      let temp = [...state.objectArr];
      return {
        ...state,
        currentObjectID: action.params,
        currentObjectIDX: temp[action.params].id,
      };

    case UPDATE_CURRENT_OBJECT_COMMENT_ID:
      return {
        ...state,
        objectCommentID: action.params,
      }

    case SHOW_OBJECT_ACTION:
      return {
        ...state,
        showObject: true,
        objectArrLoading: true,
      };

    case SHOW_OBJECT_ACTION_SUCCESS:
      return {
        ...state,
        showObject: true,
        objectArrLoading: false,
        objectArr: action.data,
        currentObjectIDX: action.data[state.currentObjectID].id,
      }

    case HIDE_OBJECT_ACTION:
      return {
        ...state,
        showObject: false,
      }

    default:
      return state;
  }
}
