import jwtDecode from 'jwt-decode'
export function queryStringToObject(queryString) {
    const pairs = queryString.substring(1).split('&');
    const array = pairs.map((el) => {
        const parts = el.split('=');
        return parts;
    });
    return Object.fromEntries(array);
}

export function changeUrlToQuery(s){
    return s.replace(/\//gi,"%2F").replace(/:/gi,"%3A")
}

export const getEmailFromJWT = (token)=>{
    const decodeToken = jwtDecode(token)
    return decodeToken['email'] || decodeToken['cognito:username']
}